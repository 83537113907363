import AOS from 'aos';
import { tns } from 'tiny-slider/src/tiny-slider';

jQuery(document).ready(() => {
  let header = $('.main-header');
  let headerHeight = document.getElementsByClassName('main-header')[0]
    .offsetHeight;
  let pos = $('.main-header').offset().top + 300;
  let win = $(window);

  win.on('scroll', function () {
    win.scrollTop() >= pos
      ? header.addClass('header-sticky') &&
        $('.wrap').css({ 'margin-top': headerHeight + 'px' })
      : header.removeClass('header-sticky') &&
        $('.wrap').css({ 'margin-top': '0' });
  });

  /* Menu Mobile */
  $('#open-menu, #close-menu').on('click', function () {
    $('.mobile-menu').toggleClass('active');
  });

  /* Close ligthbox */
  $(document).on('click', '.ligthbox_close', function () {
    $(this).closest('.ligthbox').removeClass('show');
  });

  /* AOS Animations */
  AOS.init({ once: true, duration: 1500 });

  let scrollRef = 0;

  window.addEventListener('scroll', function () {
    // increase value up to 10, then refresh AOS
    scrollRef <= 10 ? scrollRef++ : AOS.refresh();
  });

  // Add smooth scrolling to all links
  $('a').on('click', function (e) {
    if (this.hash !== '') {
      e.preventDefault();

      const hash = this.hash;
      if ($(hash).length > 0) {
        $('html, body').animate(
          {
            scrollTop: $(hash).offset().top,
          },
          1000,
          function () {
            window.location.hash = hash;
          }
        );
      }
    } // End if
  });

  const loadAlLYT = () => {
    if (typeof YT !== 'undefined' && YT.loaded) {
      $('.ekr-video-block[data-video]').each(function () {
        const videoId = $(this).data('video');
        const bgVideo = $(this).find('.ekr-vb-background-video');

        if (videoId.length > 0 && bgVideo.length > 0) {
          new YT.Player(bgVideo[0], {
            videoId,
            playerVars: {
              rel: 0,
              fs: 0,
              loop: 1,
              mute: 1,
              showinfo: 0,
              autoplay: 1,
              controls: 0,
              disablekb: 1,
              cc_load_policy: 0,
              modestbranding: 1,
            },
            events: {
              onReady: (e) => {
                e.target.playVideo();
              },
            },
          });
        }
      });
    }

    return setTimeout(() => loadAlLYT(), 500);
  };

  loadAlLYT();

  if ($('.slider-testimonials').length) {
    const sliderTestimonial = tns({
      container: '.slider-testimonials',
      items: 1,
      nav: false,
      loop: false,
      rewind: true,
      slideBy: 'page',
      controls: false,
      mouseDrag: true,
    });

    $('.button-testimonials').on('click', function (e) {
      e.preventDefault();

      sliderTestimonial.goTo('next');
    });
  }
});
